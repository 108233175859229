import {CSSProperties, ElementType, forwardRef, PropsWithChildren, useContext} from "react";
import classNames from "classnames";

import {calculateRemSize as crs} from "@pg-design/helpers-css-strings";
import {CloseIcon} from "@pg-design/icons-module";
import {getThemeBreakpoint, getThemeVariable} from "@pg-design/styles-strings";
import {Text, TextVariant, TextVariantUnionType} from "@pg-design/text-module";
import {useUserDevice} from "@pg-mono/user-device";

import {IModalCore, ModalCore} from "./ModalCore";
import {IHeaderVariant, ISystemModalTheme, ISystemModalVariant, SystemModalContext, SystemModalContextProvider} from "./SystemModalContext";

import * as styles from "./SystemModal.module.css";

interface ISystemModalProps extends Omit<IModalCore, "contentStyle"> {
    variant?: ISystemModalVariant;
    systemTheme?: ISystemModalTheme;
    headerVariant?: IHeaderVariant;
    zIndex?: number;
    closeButtonStyle?: CSSProperties;
}

/**
 * @deprecated
 */
export const SystemModal = (props: PropsWithChildren<ISystemModalProps>) => {
    const {children, variant, systemTheme, headerVariant, zIndex = 9000, closeButtonStyle, ...restProps} = props;
    const {isMobile} = useUserDevice();

    const isDarkTheme = systemTheme === "dark";
    const systemModalVariant = variant ? variant : "compact";

    return (
        <SystemModalContextProvider
            value={{variant: systemModalVariant, systemTheme: systemTheme ? systemTheme : "light", headerVariant: headerVariant ? headerVariant : "inherit"}}
        >
            <ModalCore
                closeButton={(closeModal) => (
                    <CloseButton closeModal={closeModal} systemModalVariant={systemModalVariant} isDarkTheme={isDarkTheme} style={closeButtonStyle} />
                )}
                contentStyle={{
                    border: 0,
                    borderRadius: 0,
                    backgroundColor: isDarkTheme ? getThemeVariable("colors.secondary") : "#fff",
                    padding: 0,
                    ...(typeof window !== "undefined" && window.matchMedia(`(max-width: ${getThemeBreakpoint().sm})`).matches
                        ? {
                              inset: 0
                          }
                        : {}),
                    ...(isMobile
                        ? {}
                        : {
                              borderRadius: `calc(${getThemeVariable("globals.baseUnit")} * 2)`,
                              boxShadow: getThemeVariable("shadow.1")
                          })
                }}
                overlayStyle={{
                    zIndex: zIndex,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
                {...restProps}
            >
                {children}
            </ModalCore>
        </SystemModalContextProvider>
    );
};

interface ICloseButtonProps {
    closeModal: IModalCore["onModalClose"];
    systemModalVariant: ISystemModalVariant;
    isDarkTheme: boolean;
    className?: string;
    style?: CSSProperties;
}

const CloseButton = (props: ICloseButtonProps) => {
    const {closeModal, systemModalVariant, isDarkTheme, className, style} = props;
    const {headerVariant} = useContext(SystemModalContext);

    const arrowSize = headerVariant === "gray" ? "2.0" : "2.4";
    const wrapperSize = headerVariant === "gray" ? "4.8" : "2.0";
    const wrapperColor = headerVariant === "gray" ? getThemeVariable("colors.gray.200") : "transparent";

    return (
        <div
            onClick={closeModal}
            className={className}
            style={{
                ...style,
                inset: getCloseButtonInset(systemModalVariant, headerVariant),
                position: "absolute",
                cursor: "pointer"
            }}
        >
            <CloseIcon
                fill={isDarkTheme ? "#fff" : getThemeVariable("colors.secondary")}
                size={arrowSize}
                wrapperSize={wrapperSize}
                wrapperColor={wrapperColor}
                wrapperType="square"
            />
        </div>
    );
};

function getCloseButtonInset(variant: ISystemModalVariant, headerVariant?: IHeaderVariant) {
    if (headerVariant && headerVariant === "gray") {
        return `0 0 auto auto`;
    }

    if (variant === "fit") {
        return `${crs(3)} ${crs(1.5)} auto auto`;
    }

    if (variant === "medium") {
        return `${crs(4)} ${crs(3)} auto auto`;
    }

    return "2rem 2rem auto auto";
}

/*
    Header
 */
interface ISystemModalHeaderProps extends PropsWithChildren {
    className?: string;
    as?: ElementType;
    headerTextStyle?: CSSProperties;
}

SystemModal.Header = forwardRef<HTMLDivElement, ISystemModalHeaderProps>((props, ref) => {
    const {children, className, as, headerTextStyle} = props;
    const {isMobile} = useUserDevice();
    const {variant, systemTheme, headerVariant} = useContext(SystemModalContext);

    const isMobileCompact = isMobile && variant === "compact";

    return (
        <div
            style={{
                color: systemTheme === "dark" ? "#fff" : getThemeVariable("colors.secondary"),
                background: headerVariant && headerVariant === "gray" ? getThemeVariable("colors.gray.100") : "inherit"
            }}
            className={classNames(className, getHeaderMarginClassName(variant, headerVariant))}
            ref={ref}
        >
            <div>
                <Text
                    as={as ? as : "h3"}
                    variant={getHeaderTextVariant(isMobileCompact ?? false, headerVariant)}
                    style={{
                        ...headerTextStyle,
                        ...(headerVariant && headerVariant === "gray" ? {height: crs(6), display: "flex", alignItems: "center"} : {})
                    }}
                >
                    {children}
                </Text>
            </div>
        </div>
    );
});

function getHeaderTextVariant(isMobileCompact: boolean, headerVariant?: IHeaderVariant): TextVariantUnionType {
    if (headerVariant && headerVariant === "gray") {
        return TextVariant.BODY_COPY_2;
    }

    if (isMobileCompact) {
        return TextVariant.HEADLINE_4;
    }

    return TextVariant.HEADLINE_3;
}

function getHeaderMarginClassName(variant: ISystemModalVariant, headerVariant?: IHeaderVariant) {
    if (variant === "fit" && headerVariant && headerVariant === "gray") {
        return styles.headerPaddingFitGray;
    }

    if (variant === "fit") {
        return styles.headerPaddingFit;
    }

    if (variant === "medium") {
        return styles.headerPaddingMedium;
    }

    if (variant === "spacious") {
        return styles.headerPaddingSpacious;
    }

    return styles.headerPaddingDefault;
}

/*
    Content
 */
interface ISystemModalContentProps extends PropsWithChildren {
    fullWidth?: boolean;
    className?: string;
    textStyle?: CSSProperties;
}

SystemModal.Content = (props: ISystemModalContentProps) => {
    const {children, fullWidth, className, textStyle} = props;
    const {isMobile} = useUserDevice();
    const {variant, systemTheme} = useContext(SystemModalContext);

    return (
        <div
            style={{
                color: systemTheme === "dark" ? "#fff" : getThemeVariable("colors.secondary")
            }}
            className={classNames(className, getContentWrapPaddingClass(variant, isMobile ?? false, fullWidth))}
        >
            <Text as="div" variant="body_copy_2" className="text-wrap" style={textStyle}>
                {children}
            </Text>
        </div>
    );
};

function getContentWrapPaddingClass(variant: ISystemModalVariant, isMobile: boolean, fullWidth?: boolean) {
    if (variant === "medium") {
        return styles.contentWrapPaddingMedium;
    }

    if (variant === "spacious" && fullWidth) {
        return styles.contentWrapSpaciousFullWidth;
    }

    if (variant === "spacious") {
        return styles.contentWrapSpacious;
    }

    if (variant === "fit") {
        return styles.contentWrapPaddingFit;
    }

    if (fullWidth) {
        return styles.contentWrapPaddingFullWidth;
    }

    return styles.contentWrapPadding;
}
import {CSSProperties, ElementType, forwardRef, PropsWithChildren, useContext} from "react";
import classNames from "classnames";

import {calculateRemSize as crs} from "@pg-design/helpers-css-strings";
import {CloseIcon} from "@pg-design/icons-module";
import {getThemeBreakpoint, getThemeVariable} from "@pg-design/styles-strings";
import {Text, TextVariant, TextVariantUnionType} from "@pg-design/text-module";
import {useUserDevice} from "@pg-mono/user-device";

import {IModalCore, ModalCore} from "./ModalCore";
import {IHeaderVariant, ISystemModalTheme, ISystemModalVariant, SystemModalContext, SystemModalContextProvider} from "./SystemModalContext";

import * as styles from "./SystemModal.module.css";

interface ISystemModalProps extends Omit<IModalCore, "contentStyle"> {
    variant?: ISystemModalVariant;
    systemTheme?: ISystemModalTheme;
    headerVariant?: IHeaderVariant;
    zIndex?: number;
    closeButtonStyle?: CSSProperties;
}

/**
 * @deprecated
 */
export const SystemModal = (props: PropsWithChildren<ISystemModalProps>) => {
    const {children, variant, systemTheme, headerVariant, zIndex = 9000, closeButtonStyle, ...restProps} = props;
    const {isMobile} = useUserDevice();

    const isDarkTheme = systemTheme === "dark";
    const systemModalVariant = variant ? variant : "compact";

    return (
        <SystemModalContextProvider
            value={{variant: systemModalVariant, systemTheme: systemTheme ? systemTheme : "light", headerVariant: headerVariant ? headerVariant : "inherit"}}
        >
            <ModalCore
                closeButton={(closeModal) => (
                    <CloseButton closeModal={closeModal} systemModalVariant={systemModalVariant} isDarkTheme={isDarkTheme} style={closeButtonStyle} />
                )}
                contentStyle={{
                    border: 0,
                    borderRadius: 0,
                    backgroundColor: isDarkTheme ? getThemeVariable("colors.secondary") : "#fff",
                    padding: 0,
                    ...(typeof window !== "undefined" && window.matchMedia(`(max-width: ${getThemeBreakpoint().sm})`).matches
                        ? {
                              inset: 0
                          }
                        : {}),
                    ...(isMobile
                        ? {}
                        : {
                              borderRadius: `calc(${getThemeVariable("globals.baseUnit")} * 2)`,
                              boxShadow: getThemeVariable("shadow.1")
                          })
                }}
                overlayStyle={{
                    zIndex: zIndex,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
                {...restProps}
            >
                {children}
            </ModalCore>
        </SystemModalContextProvider>
    );
};

interface ICloseButtonProps {
    closeModal: IModalCore["onModalClose"];
    systemModalVariant: ISystemModalVariant;
    isDarkTheme: boolean;
    className?: string;
    style?: CSSProperties;
}

const CloseButton = (props: ICloseButtonProps) => {
    const {closeModal, systemModalVariant, isDarkTheme, className, style} = props;
    const {headerVariant} = useContext(SystemModalContext);

    const arrowSize = headerVariant === "gray" ? "2.0" : "2.4";
    const wrapperSize = headerVariant === "gray" ? "4.8" : "2.0";
    const wrapperColor = headerVariant === "gray" ? getThemeVariable("colors.gray.200") : "transparent";

    return (
        <div
            onClick={closeModal}
            className={className}
            style={{
                ...style,
                inset: getCloseButtonInset(systemModalVariant, headerVariant),
                position: "absolute",
                cursor: "pointer"
            }}
        >
            <CloseIcon
                fill={isDarkTheme ? "#fff" : getThemeVariable("colors.secondary")}
                size={arrowSize}
                wrapperSize={wrapperSize}
                wrapperColor={wrapperColor}
                wrapperType="square"
            />
        </div>
    );
};

function getCloseButtonInset(variant: ISystemModalVariant, headerVariant?: IHeaderVariant) {
    if (headerVariant && headerVariant === "gray") {
        return `0 0 auto auto`;
    }

    if (variant === "fit") {
        return `${crs(3)} ${crs(1.5)} auto auto`;
    }

    if (variant === "medium") {
        return `${crs(4)} ${crs(3)} auto auto`;
    }

    return "2rem 2rem auto auto";
}

/*
    Header
 */
interface ISystemModalHeaderProps extends PropsWithChildren {
    className?: string;
    as?: ElementType;
    headerTextStyle?: CSSProperties;
}

SystemModal.Header = forwardRef<HTMLDivElement, ISystemModalHeaderProps>((props, ref) => {
    const {children, className, as, headerTextStyle} = props;
    const {isMobile} = useUserDevice();
    const {variant, systemTheme, headerVariant} = useContext(SystemModalContext);

    const isMobileCompact = isMobile && variant === "compact";

    return (
        <div
            style={{
                color: systemTheme === "dark" ? "#fff" : getThemeVariable("colors.secondary"),
                background: headerVariant && headerVariant === "gray" ? getThemeVariable("colors.gray.100") : "inherit"
            }}
            className={classNames(className, getHeaderMarginClassName(variant, headerVariant))}
            ref={ref}
        >
            <div>
                <Text
                    as={as ? as : "h3"}
                    variant={getHeaderTextVariant(isMobileCompact ?? false, headerVariant)}
                    style={{
                        ...headerTextStyle,
                        ...(headerVariant && headerVariant === "gray" ? {height: crs(6), display: "flex", alignItems: "center"} : {})
                    }}
                >
                    {children}
                </Text>
            </div>
        </div>
    );
});

function getHeaderTextVariant(isMobileCompact: boolean, headerVariant?: IHeaderVariant): TextVariantUnionType {
    if (headerVariant && headerVariant === "gray") {
        return TextVariant.BODY_COPY_2;
    }

    if (isMobileCompact) {
        return TextVariant.HEADLINE_4;
    }

    return TextVariant.HEADLINE_3;
}

function getHeaderMarginClassName(variant: ISystemModalVariant, headerVariant?: IHeaderVariant) {
    if (variant === "fit" && headerVariant && headerVariant === "gray") {
        return styles.headerPaddingFitGray;
    }

    if (variant === "fit") {
        return styles.headerPaddingFit;
    }

    if (variant === "medium") {
        return styles.headerPaddingMedium;
    }

    if (variant === "spacious") {
        return styles.headerPaddingSpacious;
    }

    return styles.headerPaddingDefault;
}

/*
    Content
 */
interface ISystemModalContentProps extends PropsWithChildren {
    fullWidth?: boolean;
    className?: string;
    textStyle?: CSSProperties;
}

SystemModal.Content = (props: ISystemModalContentProps) => {
    const {children, fullWidth, className, textStyle} = props;
    const {isMobile} = useUserDevice();
    const {variant, systemTheme} = useContext(SystemModalContext);

    return (
        <div
            style={{
                color: systemTheme === "dark" ? "#fff" : getThemeVariable("colors.secondary")
            }}
            className={classNames(className, getContentWrapPaddingClass(variant, isMobile ?? false, fullWidth))}
        >
            <Text as="div" variant="body_copy_2" className="text-wrap" style={textStyle}>
                {children}
            </Text>
        </div>
    );
};

function getContentWrapPaddingClass(variant: ISystemModalVariant, isMobile: boolean, fullWidth?: boolean) {
    if (variant === "medium") {
        return styles.contentWrapPaddingMedium;
    }

    if (variant === "spacious" && fullWidth) {
        return styles.contentWrapSpaciousFullWidth;
    }

    if (variant === "spacious") {
        return styles.contentWrapSpacious;
    }

    if (variant === "fit") {
        return styles.contentWrapPaddingFit;
    }

    if (fullWidth) {
        return styles.contentWrapPaddingFullWidth;
    }

    return styles.contentWrapPadding;
}
